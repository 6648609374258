import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import $ from 'jquery'
window.$yasi_Appname = 'Protlearn'
//setInterval(function(){ console.clear(); }, 3000);
var logger = function () {
  var oldConsoleLog = null;
  var pub = {};
  pub.enableLogger = function enableLogger() {
    if (oldConsoleLog == null)
      return;

    window['console']['log'] = oldConsoleLog;
  };

  pub.disableLogger = function disableLogger() {
    oldConsoleLog = console.log;
    window['console']['log'] = function () { };
  };

  return pub;
}();
$(document).ready(
  function () {
    console.log('Hai am Diya');

    logger.disableLogger();
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
