import * as React from 'react';
import { useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
export default function BroadCast() {
    let { id } = useParams();
    var connection = new window.RTCMultiConnection();
    // this line is VERY_important
    connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
    // if you want audio+video conferencing
    connection.session = {
        oneway: true,
        audio: true,
        video: true,
    };
    connection.sdpConstraints.mandatory = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
    };
    connection.onstreamended = function (event) {
        var video = document.getElementById(event.streamid);
        if (video && video.parentNode) {
            video.parentNode.removeChild(video);
        }
        /*  alert("Stream closed"); */
        document.getElementById('yasivideocontainerclientscastcontrol').style.display = 'block';
        window.location.reload()
    };
    connection.checkPresence(id, function(isRoomExist, roomid) {
        if (isRoomExist === true) {
            document.getElementById('yasivideocontainerclientscastcontrol').style.display = 'block';
        } else {
            /*alert("Host is offline or An error occured Please reload")*/
            window.location.reload()
        }
    })
    connection.onerror = function(event) {
        document.getElementById('yasivideocontainerclientscastcontrol').style.display = 'block';
    };

    connection.onstream = function (e) {
        var video = e.mediaElement;
        video.autoplay = true;
        video.allowfullscreen  = true;
        document.getElementById('yasivideocontainerclientsload').style.display = 'none';
        document.getElementById('yasivideocontainerclientscast').appendChild(video);
        document.getElementsByTagName('video')[0].webkitEnterFullscreen();
    };
    function join(){
        document.getElementById('yasivideocontainerclientscastcontrol').style.display = 'none';
        document.getElementById('yasivideocontainerclientsload').style.display = 'block';
        connection.join(id);
    }

    return (
        <DocumentTitle title={'Freemium Classroom | ' + window.$yasi_Appname}>
            <div  className="container">
            <Alert style={{ margin: '10px' }} severity="error">important : Use Latest Version of <strong>Chrome Browser </strong> for better Experience  <i>  if you browser is not chrome; may be nothing will work  </i>           </Alert>

    <h3>Live Stream by - {atob(id)}</h3>
    
                    
                    <div id="yasivideocontainerclientscast">
                    </div>
                    <center>
                <Button variant="contained"
                  color="secondary" style={{display:'none'}} id="yasivideocontainerclientscastcontrol" onClick={() => join()}
                        >Join Now</Button>
                        <CircularProgress style={{display:'none'}} id="yasivideocontainerclientsload" color="secondary" />
                        </center>
            </div>
        </DocumentTitle>
    )
}