
export const Config = {
    apiKey: "AIzaSyDGWkBJfAJHDHz5rYBXH91cPs6MLJ14tzM",
    authDomain: "protlearn.firebaseapp.com",
    databaseURL: "https://protlearn.firebaseio.com",
    projectId: "protlearn",
    storageBucket: "protlearn.appspot.com",
    messagingSenderId: "353341354235",
    appId: "1:353341354235:web:f78808d55d69a5da83405e",
    measurementId: "G-7W9ENQ6LEE"
  };