import * as React from "react";
import DocumentTitle from 'react-document-title';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
//import BroadCast from './BroadCast';
import LockOpen from '@material-ui/icons/LockOpen';
import * as firebase from "firebase/app";
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import "firebase/auth";
import {
  FirebaseAuthProvider,
  IfFirebaseUnAuthed,
  IfFirebaseAuthedAnd,
  FirebaseAuthConsumer
} from "@react-firebase/auth";

import CircularProgress from '@material-ui/core/CircularProgress';
import { Config as firebaseConfig } from "./libs/firebaseConfig";

export default function FreeClassroom() {
  var connection = new window.RTCMultiConnection();
  var usergetevevt = '';
  // this line is VERY_important
  connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
  // if you want audio+video conferencing
  connection.session = {
    oneway: true,
    audio: true,
    video: true,
  };
  connection.mediaConstraints.video.optional = [];
  connection.mediaConstraints.video.optional = [{
    facingMode: 'application'
  }];
  connection.sdpConstraints.mandatory = {
    OfferToReceiveAudio: false,
    OfferToReceiveVideo: false
  };
  connection.onstream = function (e) {
    usergetevevt = setInterval(btnGetUserInfo, 6000);
    document.getElementById('yasivideocontainerclientsload').style.display = 'none';
    var video = e.mediaElement;
    document.getElementById('yasivideocontainer').appendChild(video);
    document.getElementById('golive').style.display = 'none';
    document.getElementById('btnleaveyasi').style.display = 'block';

  }
  function btnLeave() {
    // disconnect with all users
    try {
      connection.getAllParticipants().forEach(function (pid) {
        connection.disconnectWith(pid);
      });

      // stop all local cameras
      connection.attachStreams.forEach(function (localStream) {
        localStream.stop();
      });
      document.getElementById('btnleaveyasi').style.display = 'none';
      // close socket.io connection
      connection.closeSocket();
      document.getElementById('golive').style.display = 'block';
      clearInterval(usergetevevt);
    } catch (e) {
      console.log(e);
    }
  }
  function btnGetUserInfo() {
    var numberOfUsers = connection.getAllParticipants().length;
    document.getElementById('statusofactiveyasi').innerHTML = numberOfUsers + ' users watching.'
  }
  return (
    <DocumentTitle title={'Freemium Classroom | ' + window.$yasi_Appname} >
      <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
        <Container disableGutters={true} maxWidth='false' style={{ margin: '0', background: '#D0D0D0', padding: '15px', minHeight: '100vh' }}>
          <center>
            <div style={{ textAlign: 'center' }}>

              <Alert style={{ margin: '10px' }} severity="error">important : Use Latest Version of <strong>Chrome Browser </strong> for better Experience  <i>  if you browser is not chrome; may be nothing will work  </i>           </Alert>
              <IfFirebaseUnAuthed>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<LockOpen />}
                  onClick={() => {
                    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                    firebase.auth().signInWithPopup(googleAuthProvider);

                  }}
                >
                  Sign In with Google
      </Button>
              </IfFirebaseUnAuthed>

            </div>
          </center>
          <IfFirebaseAuthedAnd
            filter={({ providerId }) => providerId !== "anonymous"}
          >
            {({ providerId }) => {
              return (
                <div>
                  <FirebaseAuthConsumer>
                    {({ user }) => {
                      console.log(user);
                      return (
                        <div>
                          <h4> Welcome, {user.displayName}</h4>
                          <p>We have set up a Broadcasting Facility for you here. With this you can do one-way communication, like television, you can launch a live video transmission by pressing the 'Go Live' button.<br />
Then it will be broadcast on the link below. Video can be viewed by anyone using this link.<br />
A Special Notice You and the link user must use the latest 'Chrome' browser</p>
                          <hr />
                          <h2>Live Stream <span id="statusofactiveyasi"></span>

                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                              startIcon={<LockOpen />}
                              onClick={() => {
                                firebase.auth().signOut();
                                btnLeave();
                              }}
                              style={{ float: 'right' }}
                            >
                              Sign Out
                </Button>
                          </h2>

                          <div>
                            <CircularProgress style={{ display: 'none', margin: '20px' }} id="yasivideocontainerclientsload" color="secondary" />
                            <div id="yasivideocontainer"></div>
                            <Button variant="contained"
                              color="primary" size="small" id="golive" onClick={() => {
                                connection.open(btoa(user.email));
                                document.getElementById('yasivideocontainerclientsload').style.display = 'block';
                              }}>Go Live</Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => btnLeave()} style={{ display: 'none' }} id="btnleaveyasi"> End Live</Button>

                            <p>Share this url to your students</p>

                            <textarea style={{ width: '50%' }}>{window.location.protocol + '//' + window.location.hostname + '/anonymous_client/live/' + btoa(user.email)}</textarea><hr />
                            <a href={'whatsapp://send?text=' + window.location.protocol + '//' + window.location.hostname + '/anonymous_client/live/' + btoa(user.email)}><button>Share on whatsapp</button></a>
                            <hr />
                          </div>
                          <Link href="https://protlearn.web.app/" >
                            Go to Home</Link> <Link href="tel:8590530098" >
                            Technical Contact</Link>
                        </div>
                      );
                    }}
                  </FirebaseAuthConsumer>
                </div>
              )
            }
            }
          </IfFirebaseAuthedAnd>
        </Container>
      </FirebaseAuthProvider>
    </DocumentTitle>
  );
};
