
import React from 'react';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
export default function Copyright() {
    return (
        <div>            <Alert style={{ margin: '10px' }} severity="error">important : Use Latest Version of <strong>Chrome Browser </strong> for better Experience  <i>  if you browser is not chrome; may be nothing will work  </i>           </Alert>
        
        <div className="yasi_Footer">
            {' © '}
            <Link color="inherit" href="https://diyainfocare.azba.in/">
               Diyainfocare | Mohammed yasim
        </Link>{' '}
        </div>
        </div>
    );
}