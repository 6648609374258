import React from 'react';


import { Route, Switch } from "react-router-dom";

import AppMain from './App_main'
import FreeClassroom from './App_freemium'
import BroadCast from './BroadCast'
import BroadCastLive from './BroadcastLive'

import Sjcetei from './Sjcetei'

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <AppMain></AppMain>
            </Route>
            <Route path="/plans">
                <AppMain></AppMain>
            </Route>
            <Route path="/features"> <AppMain></AppMain></Route>
            <Route path="/support"> <AppMain></AppMain></Route>
            <Route path="/enterprise"><AppMain></AppMain></Route>
            <Route path="/classroom">
                <FreeClassroom />
            </Route>
            <Route path="/anonymous_client/live/:id" component={BroadCast} ></Route>
            <Route path="/anonymous_client/channel/:id" component={BroadCastLive} ></Route>
            <Route path="/anonymous_client">
                <FreeClassroom />
            </Route>
            <Route path="/sjcet_ei">
              <Sjcetei/>
            </Route>
            <Route  render={function () {
                        return (
                            <h1>Page Not found</h1>
                        )
                    }} />
        </Switch>
    );
}

