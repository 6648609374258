import * as React from 'react';
import { useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
export default function BroadCastLive() {
    let { id } = useParams();
    var connection = new window.RTCMultiConnection();
    // this line is VERY_important
    connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
    // if you want audio+video conferencing
    connection.session = {
        oneway: true,
        audio: true,
        video: true,
    };
    connection.sdpConstraints.mandatory = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
    };
    connection.onstreamended = function (event) {
        var video = document.getElementById(event.streamid);
        if (video && video.parentNode) {
            video.parentNode.removeChild(video);
        }
        /*  alert("Stream closed"); */
        window.location.reload()
    };
    connection.checkPresence(id, function (isRoomExist, roomid) {
        if (isRoomExist === true) {
            connection.join(id);
        } else {
            /*alert("Host is offline or An error occured Please reload")*/
            window.location.reload()
        }
    })


    connection.onstream = function (e) {
        var video = e.mediaElement;
        video.autoplay = true;
        document.getElementById('yasivideocontainerclientscast').appendChild(video);
    };


    return (
        <DocumentTitle title={'Freemium Classroom | ' + window.$yasi_Appname}>
            <div className="container">
                <div id="yasivideocontainerclientscast">
                </div>
            </div>
        </DocumentTitle>
    )
}