import React from 'react';
import {
    NavLink,
    Switch,
    Route,
} from "react-router-dom";
import DocumentTitle from 'react-document-title';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from './snippets/Copyright'
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';

import undraw_video_call_kxyp from './svg/undraw_video_call_kxyp.svg'
import uundraw_tutorial_video_vtd1 from './svg/undraw_tutorial_video_vtd1.svg'
import { classes } from './snippets/Classes';

import { Link } from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
export default class AppMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }

    }
    componentDidMount() {

    }

    handleDrawer = (states) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        this.setState({ open: states });
    };
    render() {
        return (
            <DocumentTitle title={window.$yasi_Appname}>
                <div className="yasibody">
                    <CssBaseline />
                    <AppBar position="fixed" color="default" style={{ height: '50px', lineHeight: '50px', margin: '0', padding: '0' }}>
                        <div style={{ marginLeft: '10px' }}>
                            <IconButton color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawer(true)} style={{ float: 'left' }} >
                                <MenuIcon />
                            </IconButton>
                            <NavLink to="/">
                                <span style={{ float: 'left', fontSize: '1.2rem', fontWeight: 'bold' }}>{window.$yasi_Appname}</span>
                            </NavLink>
                        </div>
                    </AppBar>
                    <React.Fragment key="left">
                        <Drawer
                            anchor="left"
                            open={this.state.open}
                            onClose={this.handleDrawer(false)}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div
                                style={{ width: '240px' }}
                                role="presentation"
                                onClick={this.handleDrawer(false)}
                                onKeyDown={this.handleDrawer(false)}
                            />
                            <div style={{ height: '50px', marginLeft: '10px', lineHeight: '50px', padding: '0' }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer" onClick={this.handleDrawer(false)} style={{ float: 'left' }}>
                                    <CloseIcon />
                                </IconButton>
                                <NavLink to="/">
                                    <span style={{ float: 'left', fontSize: '1.2rem', fontWeight: 'bold' }}>{window.$yasi_Appname}</span>
                                </NavLink>
                            </div>
                            <Divider />
                            <List>
                                {[{ 'text': 'Features', 'link': 'features' },
                                { 'text': 'Plans & Pricing', 'link': 'plans' },
                                { 'text': 'Support', 'link': 'support' },
                                { 'text': 'Enterprise', 'link': 'enterprise' }].map((anchor) => (
                                    <div>
                                        <Link onclick={this.handleDrawer(false)} to={anchor.link} >
                                            <ListItem button key={anchor.link} >
                                                <ListItemText primary={anchor.text} />
                                            </ListItem></Link>
                                        <Divider></Divider>
                                    </div>
                                ))}
                                <div>
                                    <Link onclick={this.handleDrawer(false)} to='/classroom/' >
                                        <ListItem button key='/classroom/' >
                                            <ListItemText><Button variant="contained" color="primary">
                                                Create free Classroom
                  </Button></ListItemText>
                                        </ListItem></Link>
                                    <Divider></Divider>
                                </div>
                                <div>
                                    
                                </div>
                            </List>
                        </Drawer>
                    </React.Fragment>
                    <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: this.state.open,
                        })} style={{ marginTop: '48px' }}>
                        <Switch>
                            <Route exact path="/">

                                {/* Hero unit */}
                                <div style={{}}>
                                    <Container className="yasi_container" maxWidth="sm" style={{ paddingTop: '60px' }}>
                                        <img src={undraw_video_call_kxyp} alt="banner" width="60%" />
                                        <Typography variant="h5" align="center" color="default">
                                            {window.$yasi_Appname}' provides an online environment teaching and learning  where participants can interact, communicate, discuss, presentations and engage with learning resources.
            </Typography>
                                        <Grid container spacing={2} justify="center" style={{ marginBottom: '30px' }}>
                                            <Grid item>
                                                <NavLink to="/classroom/"><Button variant="contained" color="primary">
                                                    Create free Classroom
                  </Button></NavLink>
                                            </Grid>

                                            {/*<Grid item>
                                                <NavLink to="/anonymous_client/">
                                                    <Button variant="outlined" color="primary">
                                                        Join A Classroom
                  </Button></NavLink>
                                            </Grid>*/}
                                        </Grid>
                                    </Container>

                                    <center><h6 className="text-center">Work in progress Keep in touch</h6></center>

                                </div>
                            </Route>
                            <Route exact path="/features">
                                <div style={{margin:'30px',paddingTop:'40px'}}>
                            <h4> Free Classrom Features,</h4>
                            <ul>
                                <li>Free to use</li>
                                <li>One way communication</li>
                                <li>High Quality Audio</li>
                                <li>HD Video Transmission</li>
                                
                            </ul>
                            <img src={uundraw_tutorial_video_vtd1} alt="vid mission" style={{height:'200px'}}/>
                          <p>We have set up a Broadcasting Facility for you here. With this you can do one-way communication, like television, you can launch a live video transmission by pressing the 'Go Live' button.<br/>
Then it will be broadcast on the link below. Video can be viewed by anyone using this link.<br/>
A Special Notice You and the link user must use the latest 'Chrome' browser</p>
<hr/>
<h4> Premium Classrom</h4>
<p>Beyond Release..</p>
</div>
                            </Route>
                            <Route path="/plans"><div style={{textAlign:'center',paddingTop:'50px'}}><h2>Now we have only free version</h2></div></Route>
                            <Route path="/support"><div style={{textAlign:'center',paddingTop:'50px'}}><h1>Setting Up </h1></div></Route>
                            <Route path="/enterprise"><div style={{textAlign:'center',paddingTop:'50px'}}>On Research</div></Route>
                        </Switch>
                    </main>
                    <Copyright />
                </div>
            </DocumentTitle>
        )
    }
}