import React from 'react';
import './diya_meet.scss'
import $ from 'jquery';
import { createElement } from 'react';
export default function Sjcetei() {

    var connectioner = new window.RTCMultiConnection();

    // this line is VERY_important
    connectioner.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';

    // if you want audio+video conferencing
    connectioner.session = {
        //video:true,
        screen:true,
        video:false,
        audio:true
    };
    connectioner.bandwidth = {
        audio: 10,  // 50 kbps
        video: 100, // 256 kbps
    }
    connectioner.onstream = function (event) {
        var vid = event.stream.id
        var video = event.mediaElement;
    
        $('.diya_meet_screen').append("<div class='video' id='body" + vid + "'></div>")
        $('#body' + vid).append(video);
        $('#body' + vid).append("<div class='videoframe'></div>");
        if (event.stream.isVideo) {
            alert("hi")
            $("#"+vid).addClass('screen');
        }
    
        if (event.userid === connectioner.userid) {
            // you shared this stream
        } else {
            $('.diya_meet_people').append("<li>"+vid+"|"+event.userid+"</li>")
        }
    };
    connectioner.openOrJoin('sjcet_ei')
    connectioner.onleave = function (event) {
        alert(event.userid + ' left.');
    };
    connectioner.onstreamended = function (event) {
        var video = event.streamid;
        $('#body' + video).remove()
    };
    return (
        <div>
            <div className="diya_meet">
                <div className="diya_meet_screen"></div>
                <div>
                    <h3>Onmmet</h3>
                <ul className="diya_meet_people">

                </ul>
                </div>
                <button onClick={() => connectioner.openOrJoin('sjcet_ei')}>Join</button>
                <button onClick={() => {
                        connectioner.attachStreams.forEach(function(localStream) {
                            localStream.stop();
                        });
                }}>End</button>
            </div>
        </div>
    )
}